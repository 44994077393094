@import "../../../theme_variables";
@import "../../../vendor_variables";

@mixin select() {
  padding-right: 30px;
  height: 40px;
  border: 1px solid $io-grey-light;
  border-radius: 0;
  outline: none;
  outline-color: transparent;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background: #fff url(../../../../img/arrow.svg) calc(100% - 5px) center no-repeat;
  background-size: 20px;

  &:hover {
    border-color: $io-grey-medium;
  }
  &:focus {
    outline: none;
    outline-color: transparent;
    border-color: $io-grey-medium;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &::-ms-expand {
    display: none;
  }

  option:disabled,
  option[disabled] {
    font-weight: bold;
  }
}

.PM_ASCriterionsGroup,
.sortPagiBar {
  & > div {
    padding: 0;
  }

  select {
    @include select();
  }

}

.PM_ASBlockOutput {
  position: relative;
}

.PM_ASBlockOutput,
.sortPagiBar {
  margin: 0 25px;
  padding: 0;

  .block_content {
    margin: 0 0 20px 0;
  }
}

.PM_ASearchTitle,
.sortPagiBar label {
  display: inline-block;
  font-weight: bold;
  font-size: 14px !important;
  color: $io-grey-dark;
  text-transform: inherit;
  font-family: $font-heading;
  letter-spacing: 1.5px;
}

.sortPagiBar {
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}

ul.PM_ASCriterionGroupImage, ul.PM_ASCriterionGroupCheckbox {
  list-style-type: none;
}

.PM_ASCriterionsSEOGroupDisabled, .PM_ASCriterionsSEOGroupDisabled a, .PM_ASCriterionsSEOGroupDisabled input {
  cursor: default;
}

/*criteria group display*/
ul.PM_ASCriterionGroupImage, .PM_ASCriterionGroupColor {
  clear: both;
}

ul.PM_ASCriterionGroupImage li, .PM_ASCriterionGroupColor li {
  display: block;
  float: left;
}

ul.PM_ASCriterionGroupImage li a, .PM_ASCriterionGroupColor li a {
  display: block;
}

.PM_ASCriterionsGroupTitle {
  margin: 10px 0;
}

/*criteriaGroup collapse*/
.PM_ASCriterionsGroupCollapsed {
  display: none;
}

/*criteria display*/
.PM_ASCriterionHide, li.PM_ASCriterionHide, ul.PM_ASCriterionGroupImage li.PM_ASCriterionHide, .PM_ASCriterionGroupColor li.PM_ASCriterionHide {
  display: none;
}

/*.PM_ASCriterionHideToogleHover, .PM_ASCriterionHideToogleClick {}*/
.PM_ASHide {
  display: none;
}

/*Selections display*/
.PM_ASSelectionsBlock {
  padding-top: 10px;
}

.PM_ASSelections ul {
  list-style-type: none;
}

.PM_ASSelections ul li {
  display: inline;
}

.PM_ASBlockOutputVertical .PM_ASSelections ul li {
  display: block !important;
}

.PM_ASBlockOutputHorizontal .PM_ASSelectionsBlock {
  min-height: 25px;
}

* html .PM_ASBlockOutputHorizontal .PM_ASSelectionsBlock {
  height: 25px;
}

.PM_ASBlockOutputVertical .PM_ASCriterionsGroup {
  padding: 1%;
}

.PM_ASBlockOutputHorizontal .PM_ASCriterionsGroup select, .PM_ASBlockOutputVertical .PM_ASCriterionsGroup select {
  width: 100%;
  margin: 4px 0;
}

.PM_ASResetSearch {
  display: none;
  position: absolute;
  right: 20px;
  top: -30px;
  height: 25px;
  background-color: #fff;
  border: 1px solid $io-grey-medium;
  border-radius: 0;
  padding: 3px 5px;
  color: $text-color;
  &:hover,&:active,&:visited{
    color: $text-color;
    text-decoration: none;
  }

  @media(max-width: $screen-xs-max){
    right: 0;
    top: 0;
  }


  /*
  &:after {
    content: '';
    background: transparent url(../../../../img/close.svg) center no-repeat;
    background-size: 18px;
    border: 1px solid $io-grey-light;
    display: inline-block;
    height: 24px;
    vertical-align: middle;
    width: 24px;
    margin-left: 10px;
  }
  */
  &:hover {
    &:after {
      border-color: $io-grey-medium;
    }
  }
}

.PM_ASSelectionsDropDown {
  position: relative;
  height: 25px;
  z-index: 2;
}

.PM_ASSelectionsDropDownMenu {
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  width: 96%;
  padding: 2%;
  z-index: 2;
}

.PM_ASearchLayerBlock {
  background: #FFFFFF url(../../../../img/loading.svg) center center no-repeat;
  display: none;
}

.PM_ASearchLayerResult {
  background: #FFFFFF url(../../../../img/loading.svg) center center no-repeat;
  display: none;
}

#PM_ASearchSeoCrossLinks {
  clear: both;
}

/*Share block*/
.asShareBlock {
  margin-top: 20px;
  border: 1px solid #dddddd;
  padding: 2%;
  display: none;
}

.asShareBlock input {
  margin-left: 0 !important;
  width: 250px;
  height: 30px;
  font-size: 17px;
  line-height: 17px;
  color: $io-grey-dark;
}

.asShareLeft {
  margin-right: 2%;
  float: left;
  width: 48%;
}

.asShareRight {
  float: right;
  width: 48%;
}

/*Hidden criterion group*/
.PM_ASShowCriterionsGroupHidden {
  text-align: left;
  padding:10px;

  .btn-show-advanced {
    font-size: $font-size-base;
    color: $input-color;
    &:hover{
      color: darken($input-color,20);
    }
  }
}

.PM_ASCriterionsGroupHidden {
  display: none;
}

.PM_ASCriterionNoChoice{
  display: none;
}

/*Range value for slider*/
.PM_ASCritRangeValue {
  display: block;
  text-align: center;
  margin-top: 5px;
}

.clear {
  clear: both;
}

/*Link for showing Dropdown selection reminder on vertical block*/
.PM_ASBlockOutputVertical a.PM_ASSelectionsDropDownShowLink {
  background: transparent url(../img/005_50.gif) 0 3px no-repeat;
  padding-left: 12px;
  text-decoration: none;
}

/*Dropdown selection reminder on vertical block*/
.PM_ASBlockOutputVertical .PM_ASSelectionsDropDownMenu {
  background: #ffffff;
  border: 1px dashed #9E9E9E;
}

/*Link for removing criterion of selection*/
a.PM_ASSelectionsRemoveLink {
  background: transparent url(../img/005_15.gif) 0 3px no-repeat;
  padding-left: 12px;
  text-decoration: none;
}

a.PM_ASSelectionsRemoveLink:hover, .PM_ASCriterionLinkSelected:hover {
  text-decoration: line-through !important;
}

/*Separation of criterion block on vertical block*/
.PM_ASBlockOutputVertical .PM_ASCriterions .PM_ASCriterionsGroupOuter {
  padding: 0 13px;
  margin-top: 10px;
}

.PM_ASBlockOutputVertical .PM_ASCriterions .PM_ASCriterionsGroupTitle {
  padding: 0 13px 5px 13px;
  color: $io-grey-dark;
  border-bottom: 1px solid #c6c6c6;
}

/*Number products on vertical block*/
.PM_ASBlockOutputVertical .PM_ASBlockNbProduct {
  padding-top: 10px;
  text-align: center;
}

/*Submit search on vertical block*/
.PM_ASBlockOutputVertical .PM_ASSubmitSearch {
  margin: 10px auto;
  margin-bottom: 0;
}

/*Color square size*/
.PM_ASCriterionGroupColor li a {
  width: 15px;
  height: 15px;
}

.PM_ASCriterionGroupColor.color_to_pick_list li {
  border: 1px solid #D6D4D4;
  display: inline-block;
  float: none;
}

.PM_ASCriterionGroupColor.color_to_pick_list li a {
  width: 23px;
  height: 23px;
  margin: 1px !important;
}

.PM_ASCriterionGroupColor.color_to_pick_list li.PM_ASCriterionHide {
  display: none;
}

/*Selected class for image and square color*/
.PM_ASCriterionGroupImage .PM_ASCriterionLink, .PM_ASCriterionGroupColor .PM_ASCriterionLink {
  margin: 4px !important;
}

.PM_ASCriterionGroupImage .PM_ASCriterionLinkSelected, .PM_ASCriterionGroupColor .PM_ASCriterionLinkSelected {
  border: 2px solid #999;
  margin: 2px !important;
}

/*Selected class for link*/
.PM_ASCriterionGroupLink .PM_ASCriterionLinkSelected, .PM_ASCriterionGroupLink .PM_ASCriterionLink:hover {
  font-weight: bold;
}

/*Selected class for label with link*/
.PM_ASCriterionGroupCheckbox .PM_ASLabelCheckboxSelected .PM_ASLabelLink, .PM_ASCriterionGroupCheckbox .PM_ASLabelCheckbox .PM_ASLabelLink:hover {
  font-weight: bold;
}

/*Hidden criterion block on horizontal block*/
.PM_ASBlockOutputHorizontal .PM_ASCriterionGroupToogleHover {
  background-color: $io-grey-light;
}

/*Lateral space for price range*/
.PM_ASPriceRange {
  margin: 0 10px !important;
}

/*Remove checkbox border from IE*/
.PM_ASCriterionCheckbox {
  border: 0;
  vertical-align: middle;
}

/*Style for hidden criteria*/
.PM_ASCriterionDisable {
  -khtml-opacity: .50;
  -moz-opacity: .50;
  -ms-filter: "alpha(opacity=50)";
  filter: alpha(opacity=50);
  opacity: .50;
  zoom: 1
}

/*Hide toogle link*/
.PM_ASCriterionHideToogleLink {
  background: url(../img/arrow-down2.gif) 0 0 no-repeat;
  padding-left: 20px;
  font-size: 10px;
  margin-top: 10px;
  display: block;
}

/* criteriaGroup collapse */
.PM_ASCriterionsGroupCollapsable {
  padding-right: 20px;
}

.PM_ASCriterionsArrowleft {
  background: url(../img/arrow-left.png) no-repeat right center;
}

.PM_ASCriterionsArrowDown {
  background: url(../img/arrow-down.png) no-repeat right center;
}

/*Reset group link*/
.PM_ASResetGroup {
  font-size: 12px;
}

#mobile-filter-overlay {
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  height: 100%;
  overflow: auto;

  form {
    margin-top: 6px;

    .PM_ASSubmitSearch {
      width: calc(50% - 10px);
      border-radius: 0;
      border: 0;
      outline: 0;
      background-color: $io-grey-light;
      margin: 4px 5px 0 0;
      float: right;
      padding: 7px;
    }
  }

  .page-heading {
    margin: 0 25px;
  }

  .close {
    background: transparent url(../../../../img/close.svg) center no-repeat;
    background-size: 18px;
    border: 1px solid $io-grey-light;
    display: inline-block;
    margin-top: 10px;
    height: 30px;
    width: 30px;
    opacity: 1;
  }

  .filter-header h4 {
    display: none;
  }

  .PM_ASResetSearch {
    margin-top: 10px;

    &:after {
      height: 30px;
      width: 30px;
    }
  }
}

.top-pagination-content-mobile {
  margin: 0;
  background-color: $io-blue-light;
  border: 1px solid $io-blue-dark;
  padding: 15px 15px 30px;
  margin-bottom: 15px;
  .filter-phrase-mobile {
    h5 {
      color: #fff;
      font-weight: 400;
      margin-left: 0px;
      margin-bottom: 15px;
    }

  }
  div.col-xs-6,
  #mobile-filter-trigger {
    display: table;
    > * {
      display: table-cell !important;
      vertical-align: middle;
      @include select();
      width: 100%;
      line-height: 32px;
      padding-left: 5px;
    }
  }
}



